.b-belovedUser {
  &__list {
    display: flex;
    @media screen and (max-width: 1380px) {
      justify-content: center;
    }
    flex-wrap: wrap;
    @include media('<tablet') {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

.b-belovedUserCard {
  max-width: 300px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
