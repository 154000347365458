.b-cohortBel {
  margin-top: 10px;

  &.-editSession {
    .b-elmDetails__title {
      font-size: 16px;
      width: 125px;
      padding-top: 2px;
    }
  }

  &__timePickers {
    display: flex;
    width: calc(100% - 5px);
    gap: 5px;
    max-width: 170px;

    &-time {
      width: 100px;
    }

    &.-additional {
      margin-top: 2px;
      font-size: 16px;
    }

    .react-datepicker-wrapper {
      width: 100px;
    }

    .react-datepicker__input-container {
      width: 100px;
      box-sizing: border-box;
    }
  }

  &__activity-img {
    width: 20px;
  }

  .label {
    font-weight: bold;
  }

  .cohort {
    //padding-left: 64px;
    max-width: 780px;
    width: auto;
    margin-right: 0;
    &_details {
      display: flex;
      justify-content: space-between;
      &_part {
        width: 48%;
      }
      &_header {
        display: flex;
      }
      &_labels {
        margin-right: 40px;
      }
      &_btn_wrap {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 15px;
        margin-top: -10px;
      }
      &_item {
        display: flex;
        padding-bottom: 15px;
      }
      &_title {
        min-width: 106px;
        max-width: 106px;
        margin-right: 20px;
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.16px;
        color: var(--gray-60);
      }
      &_val {
        font-size: 14px;
        color: var(--gray-60);
        line-height: 1.43;
        font-weight: bold;

        a {
          color: var(--gray-60);
          font-weight: bold;
          &:hover {
            color: var(--primary-60);
          }
        }
      }
      &_editCohort {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .title {
    margin: 30px 0 9px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 740px;
    width: auto;
  }

  .link {
    margin: 0;
    display: flex;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--primary-60);
    align-items: center;
    font-weight: normal;

    &__icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }

    &__ic {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background-color: var(--primary-60);
    }
    input {
      min-width: 320px;
      @media screen and (max-width: 824px) {
        min-width: 280px;
      }
      &:before {
        z-index: 0;
      }
    }
  }
  .react-datepicker__input-container {
    padding-left: 10px;
  }
  .error-notification {
    span {
      font-weight: normal;
    }
  }

  .date {
    display: flex;
    width: 100%;
    font-weight: bold;
  }

  .picker {
    width: 120px;
  }

  .time {
    width: 80px;
    margin: 0;
    padding-left: 15px;
  }

  .resource {
    display: flex;
    justify-content: center;
    align-content: center;

    .b-button-icon {
      margin-top: 2px;
      margin-right: 5px;
    }

    &__btnIcon {
      background-color: var(--primary-navy);
      // import ResourceIc from "../../../../../assets/images/icons/resource_details_ic.svg";
      width: 23px;
      height: 23px;

      &:hover {
        background-color: var(--primary-60);
      }

      &.-view {
        -webkit-mask: url(../../images/icons/resource_details_ic.svg) no-repeat center;
        mask: url(../../images/icons/resource_details_ic.svg) no-repeat center;
      }

      &.-copy {
        -webkit-mask: url(../../images/icons/copy_ic.svg) no-repeat center;
        mask: url(../../images/icons/copy_ic.svg) no-repeat center;
      }
    }
  }

  .panelRow {
    padding-right: 5px;

    @media screen and (max-width: 824px) {
      max-width: 443px;
    }
  }

  .input-wrapper {
    div {
      &:before {
        z-index: 0;
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-right: 5px;
    box-sizing: border-box;

    @media screen and (max-width: 824px) {
      max-width: 443px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 10px 0;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      color: var(--gray-60);

      span {
        padding-right: 10px;
      }
    }
  }

  &__header {
    position: relative;

    @include media('<desktop') {
      padding-left: 40px;
    }
  }

  &__backBtn {
    position: absolute;
    left: -42px;
    top: -4px;

    @include media('<desktop') {
      left: 0;
    }
  }
  &__msg-description {
    min-height: 400px;

    @media screen and (max-width: 824px) {
      min-height: 200px;
    }
  }

  &__title {
    &__remove {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: bold !important;
      line-height: 1.5;
      color: var(--primary-navy);
      cursor: pointer;
    }
  }
  .b-EAModal__buttons {
    button {
      margin: 0 12px;
    }
  }
  &__panel {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;

    @include media('<tablet') {
      flex-direction: column-reverse;
    }
  }
  &__image {
    position: relative;
    display: flex;
    width: 120px;
    justify-content: flex-end;
  }
}
