.container {
  min-height: calc(100vh);
  flex-direction: column;
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  &-header {
    height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    padding-top: 80px;
  }
}

@media screen and (max-width: 824px) {
  .container {
    justify-content: start;
  }
}

.blue-container {
  background-color: #222;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.white-container {
  background-color: var(--background);
  color: var(--gray-60);
}

.container-row {
  display: flex;
  flex-direction: row;
  margin: 5px;
  align-items: center;
  justify-content: center;
}

.container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-page-center {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}

.appContainer {
  position: relative;
  display: flex;
  min-height: 100vh;

  .-desktop {
    @include media('<desktop') {
      display: none !important;
    }
  }

  .-mobile {
    @include media('>desktop') {
      display: none !important;
    }
  }

  @include media('<desktop') {
    flex-direction: column;
  }
}

.pageContainer {
  position: relative;
  flex-grow: 1;
  z-index: 1;
  border-left: solid 1px rgba(0, 0, 0, 0.06);
  background: var(--background);

  &.-noRelative {
    position: static;
  }

  &__content {
    padding: 58px 63px;
    min-width: 1px;

    @include media('<widescreen') {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include media('<tablet') {
      padding: 30px 20px 40px 20px;
    }
  }

  &__title_suborg {
    font-family: Rubik;
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-navy);
    margin-bottom: 40px;

    @include media('<tablet') {
      font-size: 20px;
      text-align: center;
      color: var(--gray-60);
    }
  }

  &__primaryTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-navy);
    margin-bottom: 40px;

    @include media('<desktop') {
      color: var(--gray-60);
      font-size: 20px;
      text-align: center;
    }
  }

  &__title {
    font-family: Rubik;
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-navy);
    margin: 0 0 40px;

    @include media('<tablet') {
      text-align: center;
    }
  }
}

.comingSoonContainer {
  display: flex;
  min-height: 100vh;
  text-align: center;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.container-button-wrapper {
  margin-top: 30px;
  margin-bottom: 15px;
}

.page-container {
  flex: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}
